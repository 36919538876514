import { Button, Divider, Link } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExitToApp from '@mui/icons-material/ExitToApp';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useKeycloak } from 'keycloak-react-web';
import './denseAppBar.css'

export default function DenseAppBar() {
  const { keycloak, initialized } = useKeycloak();
  const userName = initialized && keycloak.authenticated && keycloak.tokenParsed && (keycloak.tokenParsed as any)['preferred_username']
  const iis = initialized && keycloak.tokenParsed && (keycloak.tokenParsed as any)['iss'];
  const handleLogout = () => {
    window.location.href = keycloak.createLogoutUrl().split('?')[0];
  }
  return (
    <Box sx={{
      borderTopColor: '#2e9f64',
      borderTopWidth: '6px',
      borderTopStyle: 'solid',
      boxShadow: '0px 0 15px rgba(0, 0, 0, 0.8)',
      padding: 0,
    }} >
      <AppBar position="static" sx={{
        color: 'black',
        border: 1,
        borderColor: '#d6e5e8',
        backgroundColor: 'white',
        minHeight: '64px',
        padding: 0,
      }}>
        <Toolbar sx={{
          backgroundColor: 'white',
          color: 'black',
          minHeight: '54px!important',
          maxHeight: '54px!important',
          paddingLeft: '10px!important',
        }}>
          <div style={{ marginTop: '10px' }}>
            <Link href={`https://www.climateearth.com/`}>
              <img
                height="62px"
                width="auto"
                src="assets/img/ce-logo.gif"
                alt="Climate Earth"
              />
            </Link>
          </div>
          <div style={{ flexGrow: 1 }} />

          <div style={{ marginTop: '5px' }}>
            <AccountCircle sx={{ marginRight: '5px' }} />
          </div>
          <div style={{ marginTop: '4px', marginRight: '5px' }}>
            <Link href={`${iis}/account/`}> {userName} </Link>
          </div>

          <Divider orientation="vertical" flexItem style={{ margin: '3px' }} />

          <div style={{ marginTop: '5px', marginLeft: '5px' }}>
            <Button disableElevation startIcon={<ExitToApp />} onClick={handleLogout}>
              <Typography variant="subtitle2" color="inherit" component="span" >
                Sign Out
              </Typography>
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
