import Keycloak from 'keycloak-js'
 
// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
export const keycloak = new Keycloak({
    clientId: "epd-browser",
    url:"https://auth.climateearth.com/auth",
    realm:"ClimateEarth-Legacy"

})
 